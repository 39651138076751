import React, { Component } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ToggleCustom } from "../../../../components/ToggleCustom";
import { pessoa_pacienteModel, pessoaModel } from "../../../../resource/request";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";
import { TextAreaCustom } from "../../../../components/TextAreaCustom";
import SpinnerCustom from "../../../../components/SpinnerCustom";

interface State {
    msg: string,
    pessoa: pessoaModel,
    pessoa_paciente: pessoa_pacienteModel,
    loading: boolean;
    isSubmitted: boolean;

}

interface Props {
    mostrarBotao?: boolean;
}

class DadosHiostricos extends Component<any> {
    state: State = {
        msg: '',
        pessoa: store.getState().user?.user,
        pessoa_paciente: store.getState().user?.user?.pessoa_paciente?.find(() => true) || {} as pessoa_pacienteModel,
        loading: false,
        isSubmitted: false,
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        this.setState({
            pessoa_paciente: {
                ...this.state.pessoa_paciente,
                pessoa_id: store.getState().user?.user.id,
                [name]: value,
                loading: false,
            }
        });
    };

      handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ loading: true });

        api_app.atualizarPessoaPaciente(
            api_app.atualizarPessoaPaciente(this.state.pessoa_paciente.id === undefined ? 0 : this.state.pessoa_paciente.id, this.state.pessoa_paciente),
            this.state.pessoa_paciente
        ).then(x => {
            const msg = "Seus dados foram atualizados com sucesso!";
            this.setState({ msg }); 
        }).catch(error => {
            console.error("Erro ao atualizar os dados:", error);
        }).finally(() => {
            this.setState({ loading: false }); 
        });
    
        this.props.router.updateStore();
    };

   

render() {
    if (this.state.pessoa.cpf_cnpj == '') {
        return <div className="flex justify-center items-center h-screen">
            <SpinnerCustom />
        </div>
    }

    return (
        <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Minha saúde "
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <ToggleCustom
                        label="Fuma?"
                        value={this.state?.pessoa_paciente?.flg_tabagismo || 'S'}
                        name='flg_tabagismo'
                        onChange={this.handleChange}
                        defaultValue={this.state?.pessoa_paciente?.flg_tabagismo || 'N'}
                    />
                    <ToggleCustom
                        label="Bebe?"
                        value={this.state?.pessoa_paciente?.flg_etilismo || 'S'}
                        name='flg_etilismo'
                        onChange={this.handleChange}
                        defaultValue={this.state?.pessoa_paciente?.flg_etilismo || 'N'}
                    />
                    <ToggleCustom
                        label="Usou ou Usa Drogas?"
                        value={this.state?.pessoa_paciente?.flg_utilizou_drogas || 'S'}
                        name='flg_utilizou_drogas'
                        onChange={this.handleChange}
                        defaultValue={this.state?.pessoa_paciente?.flg_utilizou_drogas || 'N'}
                    />
                    <ToggleCustom
                        label="Pratica Exercícios Físicos?"
                        value={this.state?.pessoa_paciente?.flg_pratica_exercicio || 'S'}
                        name='flg_pratica_exercicio'
                        onChange={this.handleChange}
                        defaultValue={this.state?.pessoa_paciente?.flg_pratica_exercicio || 'N'}
                    />
                </div>
                <div className="grid gap-4 mb-4">
                    <TextAreaCustom
                        label="Você possui alguma alergia?"
                        value={this.state?.pessoa_paciente?.alergias || ''}
                        name='alergias'
                        onChange={this.handleChange}
                    />
                    <TextAreaCustom
                        label="Liste aqui as doenças e tratamentos que você tenha realizado anteriormente:"
                        value={this.state?.pessoa_paciente?.doencas_previas || ''}
                        name='doencas_previas'
                        onChange={this.handleChange}
                    />
                    <TextAreaCustom
                        label="Informe aqui se estiver com alguma doença ou passando por algum tratamento:"
                        value={this.state?.pessoa_paciente?.doencas_atuais || ''}
                        name='doencas_atuais'
                        onChange={this.handleChange}
                    />
                    <TextAreaCustom
                        label="Informe se e quais medicações de uso contínuo você faz uso:"
                        value={this.state?.pessoa_paciente?.medicacoes_continuas || ''}
                        name='medicacoes_continuas'
                        onChange={this.handleChange}
                    />
                    <TextAreaCustom
                        label="Já fez alguma cirurgia? Qual? Em que ano?"
                        value={this.state?.pessoa_paciente?.cirurgias || ''}
                        name='cirurgias'
                        onChange={this.handleChange}
                    />
                </div>
                {this.props.mostrarBotao !== false && (
                    <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                            disabled={this.state.loading || this.state.isSubmitted}
                        />
                    </div>
                )}
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    );
}
}

export default withRouter(DadosHiostricos);