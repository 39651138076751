export const convertDateToDmyFormat = (date) => {
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z)?)?$/;
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

    let isoDate;

    const createDateFromString = (dateString) => new Date(dateString);

    if (isoDateRegex.test(date)) {
        isoDate = createDateFromString(`${date}T00:00:00`);
    } else if (isoDateTimeRegex.test(date)) {
        if (date.includes('Z')) {
            const regionalDate = date.slice(0, -1);
            isoDate = new Date(regionalDate);
        } else {
            isoDate = new Date(date);
        }
    } else {
        isoDate = new Date();
    }

    const day = String(isoDate.getDate()).padStart(2, '0');
    const month = String(isoDate.getMonth() + 1).padStart(2, '0');
    const year = isoDate.getFullYear();

    return `${day}/${month}/${year}`;
};

export const combineDateTimeToIso = (date, time) => {
    const dateString = date.toISOString();
    const index = dateString.indexOf('T');
    const modifiedString = dateString.slice(0, index + 1);

    return new Date(`${modifiedString}${time}:00`);
};

export const addDurationOnStartTime = (startTime, duration) => {
    const [hours, minutes] = duration.split(':').map(Number);
    const totalMilliseconds = (hours * 60 + minutes) * 60 * 1000;
    return new Date(startTime.getTime() + totalMilliseconds);
};

export const parseDmyToDate = (date) => {
    const [day, month, year] = date.split('/');
    return new Date(`${year}-${month}-${day} 00:00`);
};

export const calculateAgeToMeusPacientesFormat = (fullDateString) => {
  const convertedDateString = convertDateToDmyFormat(fullDateString);
  const [day, month, year] = convertedDateString.split('/').map(Number);
  const dateOfBirthObj = new Date(year, month - 1, day);
  const today = new Date();
  let age = today.getFullYear() - dateOfBirthObj.getFullYear();

  const isBirthdayPassed = 
    today.getMonth() > dateOfBirthObj.getMonth() || 
    (today.getMonth() === dateOfBirthObj.getMonth() && today.getDate() >= dateOfBirthObj.getDate());
  
  if (!isBirthdayPassed) {
    age--;
  }

  return `${day}/${month}/${year} - ${age} anos`;
};
