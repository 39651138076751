import { ChangeEvent, Component } from "react";
import { TittleCustom } from "../../../components/TittleCustom";
import InputCustom from "../../../components/InputCustom";
import { SelectEspecialidadeCustum } from "../meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum";
import { Pagination } from "flowbite-react";
import {
  FilterDto,
  pessoa_especialidadeModel,
  profissaoModel,
  pessoaModel, agendaModel
} from "../../../resource/request";
import { store } from "../../../redux/store";
import ButtonAppointmentScheduling from "../../../components/ButtonAppointmentScheduling";
import withRouter from "../../../helpers/withRouter";
import api_app from "../../../apis/api_app";
import { format, parseISO } from 'date-fns';
import { Accordion } from "flowbite-react";
import ButtonNewQueryCustom from "../../../components/ButtonNewQueryCustom";
import SpinnerCustom from "../../../components/SpinnerCustom";
import { formatMoneyToBRL } from "../../../helpers/moneyHelpers";

interface State {
  agendas: pessoaModel[];
  minhaAgenda: agendaModel[];
  showModal: boolean;
  especialidades: pessoa_especialidadeModel;
  profissaoModel: profissaoModel;
  currentPage: number;
  perPage: number;
  totalpg: number;
  filter: FilterDto;
  loading: boolean;
}

class Agenda extends Component<any, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      agendas: [],
      minhaAgenda: [],
      showModal: false,
      profissaoModel: {} as profissaoModel,
      especialidades: {
        pessoa_id: store.getState().user?.user.id,
        flg_status: 'A',
        flg_visivel: 'S',
        dat_criado_em: new Date(),
        num_especialidade_rqe: "",
        valor_atendimento_pa: 0,
        vlr_atendimento: 0
      } as pessoa_especialidadeModel,
      currentPage: 1,
      perPage: 50,
      totalpg: 50,
      filter: {} as FilterDto,
      loading: true
    };

  }


  componentDidMount(): void {
    this.fetchAgendas(1);
    this.fetchAgendasPessoa(1);
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value
      }
    });
  };

  shouldDisplayAppointment = (appointment: any) => {
    return (
      appointment.status_agenda.id === 2 ||
      appointment.status_agenda.id === 3 ||
      appointment.status_agenda.id === 4
    );
  };

  getFilter = () => {
    const { filter } = this.state;
    let formattedInitialDate = '';
    let formattedFinalDate = '';

    if (filter.perido_inicial && typeof filter.perido_inicial === 'string') {
      const parsedDate = parseISO(filter.perido_inicial);
      if (!isNaN(parsedDate.getTime())) {
        formattedInitialDate = format(parsedDate, 'yyyy-MM-dd');
      }
    } else {
      formattedInitialDate = format(new Date(), 'yyyy-MM-dd');
    }

    if (filter.perido_final && typeof filter.perido_final === 'string') {
      const parsedDate = parseISO(filter.perido_final);
      if (!isNaN(parsedDate.getTime())) {
        formattedFinalDate = format(parsedDate, 'yyyy-MM-dd');
      }
    } else {
      formattedFinalDate = format(new Date(), 'yyyy-MM-dd');
    }

    const formattedInitialhours = filter.hora_inicio ? format(parseISO(`2000-01-01T${filter.hora_inicio}`), 'HH:mm') : '';
    const formattedFinalhours = filter.hora_final ? format(parseISO(`2000-01-01T${filter.hora_final}`), 'HH:mm') : '';
    return {
      data_inicial: formattedInitialDate,
      data_final: formattedFinalDate,
      horario_inicial: formattedInitialhours,
      horario_final: formattedFinalhours,
      especialidades: filter.especialidades,
      page: this.state.currentPage,
      limit: this.state.totalpg,
      pessoa_id: store.getState().user.user.id || 1
    }
  }

  Filter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    api_app.getAgendaMedicos(this.getFilter()).then((resp) => {
      this.setState({
        agendas: resp.data.data,
        totalpg: resp.data.totalPages,
        currentPage: resp.data.page,
        loading: false
      });
    }).catch((error) => {
      console.error('Erro ao buscar agendas:', error);
    });
  };

  fetchAgendas = (page: number) => {
    api_app.getAgendaMedicos(this.getFilter()).then((resp) => {
      this.setState({
        agendas: resp.data.data,
        totalpg: resp.data.totalPages,
        currentPage: resp.data.page,
        loading: false
      });
    });
  };


  fetchAgendasPessoa = (page: number) => {
    api_app.getAgendaPessoa(this.getFilter()).then((resp) => {
      this.setState({
        minhaAgenda: resp.data.data,
        totalpg: resp.data.totalPages,
        currentPage: resp.data.page,
        loading: false
      });
    });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleChangeEspecialidades = (especialidade_id: string) => {
    this.setState({
      filter: {
        ...this.state.filter,
        especialidades: especialidade_id
      }
    });
  };

  handleChangeEspecialidade = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    this.setState({
      especialidades: {
        ...this.state.especialidades,
        [name]: value
      },
      filter: {
        ...this.state.filter,
        especialidades: value
      }
    });
  };

  handleNextPage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.fetchAgendas(page);
    });
  };

  handlePrevPage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.fetchAgendas(page);
    });
  };

  render() {

    function formatDate(date: Date | string | number) {
      const formattedDate = new Date(date);
      const day = formattedDate.getDate();
      const month = formattedDate.getMonth() + 1;
      const year = formattedDate.getFullYear();
      return `${day}/${month}/${year}`;
    }

    function formatTime(date: Date | string | number) {
      const formattedTime = new Date(date);
      const hours = formattedTime.getHours().toString().padStart(2, '0');
      const minutes = formattedTime.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
    const { filter } = this.state;
    let formattedInitialDate = '';
    let formattedFinalDate = '';

    if (filter.perido_inicial && typeof filter.perido_inicial === 'string') {
      const parsedDate = parseISO(filter.perido_inicial);
      if (!isNaN(parsedDate.getTime())) {
        formattedInitialDate = format(parsedDate, 'yyyy-MM-dd');
      }
    } else {
      formattedInitialDate = format(new Date(), 'yyyy-MM-dd');
    }

    if (filter.perido_final && typeof filter.perido_final === 'string') {
      const parsedDate = parseISO(filter.perido_final);
      if (!isNaN(parsedDate.getTime())) {
        formattedFinalDate = format(parsedDate, 'yyyy-MM-dd');
      }
    } else {
      formattedFinalDate = format(new Date(), 'yyyy-MM-dd');
    }

    return (
      <>
        <Accordion>
          <Accordion.Panel>
            <Accordion.Title>
              <TittleCustom name="Minhas Consultas" />
            </Accordion.Title>
            <Accordion.Content>
              <div className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Data
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Hora
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Profissional
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Especialidade
                      </th>
                      <th scope="col" className="px-6 py-3">
                        situação
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Valor
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Acessar consulta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.minhaAgenda.map((agenda) => {
                      return (
                        this.shouldDisplayAppointment(agenda) && (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="px-6 py-3">
                              {agenda.dia ? formatDate(agenda.dia) : '-'}
                            </td>
                            <td className="px-6 py-3">
                              {agenda.horario ? formatTime(agenda.horario) : '-'}
                            </td>
                            <td className="px-6 py-3">
                              {agenda.pessoa?.nome}
                            </td>
                            <td className="px-6 py-3">
                              {agenda.especialidade?.especialidade}
                            </td>
                            <td className="px-6 py-3">
                              {agenda.status_agenda?.status_agenda}
                            </td>
                            <td className="px-6 py-3">
                              {formatMoneyToBRL(agenda.valor)}
                            </td>
                            <td className="px-6 py-3">
                              <ButtonNewQueryCustom
                                link={'/dados-profissionais/agenda/atendimento-consulta/' + agenda.id}
                                name="Consultar"
                              />
                            </td>
                          </tr>))
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-end mt-4">
                <div className="flex sm:justify-center">
                  <Pagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalpg} onPageChange={this.handleNextPage}
                    showIcons
                    previousLabel="anterior"
                    nextLabel="próxima"
                  />
                </div>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
        <div>
          <TittleCustom
            name="Agendamento de Consulta"
          />
        </div>
        <div>
          <div className="mb-5">
            <form onSubmit={this.Filter}>
              <div className="grid gap-6 md:grid-cols-2">
                <InputCustom
                  label="Período Inicial:"
                  value={formattedInitialDate}
                  type="date"
                  name="perido_inicial"
                  placeholder="Período Inicial"
                  onChange={this.handleFilterChange}

                />
                <InputCustom
                  label="Período Final:"
                  value={formattedFinalDate}
                  type="date"
                  name="perido_final"
                  placeholder="Período Final"
                  onChange={this.handleFilterChange}

                />
                <SelectEspecialidadeCustum
                  profissao_id={this.state?.profissaoModel?.id || 0}
                  value={this.state?.especialidades?.id?.toString() || ""}
                  onChange={this.handleChangeEspecialidade}
                />
                <div>
                  <button
                    type="submit"
                    className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-900 rounded-lg border border-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                  >
                    <svg
                      className="w-4 h-4 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                    Pesquisar
                  </button>
                </div>

              </div>
            </form>
          </div>
          {this.state.loading ? (
            <div className="flex justify-center items-center h-screen bg-gray-100">
              <SpinnerCustom />
            </div>
          ) : (
            <div>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        MÉDICO
                      </th>
                      <th scope="col" className="px-6 py-3">
                        ESPECIALIDADE
                      </th>
                      <th scope="col" className="px-6 py-3 text-center">
                        OPÇÕES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.agendas.map((agenda) => (
                      <tr key={agenda.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {agenda?.nome}
                        </td>
                        <td className="px-6 py-4">
                          {agenda?.pessoa_especialidade?.map((z, index) => (
                            <span key={index}>{z.especialidade?.especialidade}</span>
                          ))}
                        </td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center justify-center w-full">
                            <ButtonAppointmentScheduling
                              link={"/agendamento-de-consulta/agendar-consulta/" + agenda.id}
                              name="Agendar Consulta"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


              </div>
              <div className="flex items-center justify-end mt-4">
                <div className="flex overflow-x-auto sm:justify-center">
                  <Pagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalpg}
                    onPageChange={this.handleNextPage}
                    showIcons
                    previousLabel="anterior"
                    nextLabel="próxima"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Agenda);
