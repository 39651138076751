import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import { SelectEspecialidadeCustum } from "./SelectEspecialidadesCustum";
import InputCustom from "../../../../../components/InputCustom";
import {
    pessoa_especialidadeModel,
    pessoa_profissionalModel,
    pessoaModel,
    profissaoModel
} from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import withRouter from "../../../../../helpers/withRouter";
import FileInputCustom from "../../../../../components/FileInputCustom";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";

const profissao: Option[] = [
    { key: "1", value: "Clinico Geral", disabled: false },
];

interface State {
    pessoa: pessoaModel,
    especialidades: pessoa_especialidadeModel,
    msg: string;
    profissaoModel: profissaoModel;
    isSubmitted: boolean;


}

export default withRouter(class CreateEspecialidade extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        profissaoModel: {} as profissaoModel,
        especialidades: {
            pessoa_id: store.getState().user?.user.id,
            flg_status: 'A',
            flg_visivel: 'S',
            dat_criado_em: new Date(),
            num_especialidade_rqe: "",
            valor_atendimento_pa: 0,
            vlr_atendimento: 0
        } as pessoa_especialidadeModel,
        msg: "",
        isSubmitted: false,

    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        if (name === 'profissao') {
            this.setState({
                profissaoModel: {
                    ...this.state.profissaoModel,
                    id: parseInt(value)
                }
            });
        } else {
            this.setState({
                especialidades: {
                    ...this.state.especialidades,
                    [name]: value
                }
            });
        }
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.state.especialidades.especialidade_id = parseInt(this.state?.especialidades?.especialidade_id?.toString() || "")
        api_app.criarEspecialidade(this.state.especialidades).then(x => {
            const msg = "Seus dados foram atualizados com suscesso!";
            this.setState({ msg, isSubmitted: true });
            this.props.router.updateStore();
        }
        )
    };

    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                        <TittleCustom
                            name="Adicionar Especialidade"
                        />
                        <ButtonReturnCustom
                            link="/dados-profissionais"
                        />
                    </div>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">

                                <SelectEspecialidadeCustum
                                    onChange={this.handleChange}
                                />
                                <InputCustom
                                    label="UF:"
                                    value={this.state.especialidades.uf || ''}
                                    type="text"
                                    name="uf"
                                    placeholder="UF"
                                    onChange={this.handleChange}
                                />
                                <InputCustom
                                    label="Nº Registro/Nº RQE:"
                                    value={this.state.especialidades.num_especialidade_rqe || ''}
                                    type="text"
                                    name="num_especialidade_rqe"
                                    placeholder="Nº Especialidades/ Nº RQE"
                                    onChange={this.handleChange}
                                />
                                <FileInputCustom
                                    label="Título De Especialidade (Opcional):"
                                    name="titulo-de-especialidade"
                                    value=""
                                />
                                <InputCustom
                                    label="Valor da Consulta:"
                                    value={this.state?.especialidades?.vlr_atendimento?.toString() || ''}
                                    type="text"
                                    name="vlr_atendimento"
                                    placeholder="Valor da Consulta"
                                    onChange={this.handleChange}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                        {!this.state.isSubmitted && (
                            <ButtonSaveCustom
                                type="submit"
                                name="Salvar"
                            />
                        )}
                    </div>
                    <p className="mt-2 text-sm text-green-600 dark:text-green-500"> {this.state.msg}</p>
                </form>
            </>
        )
    }
})