import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import { TittleCustom } from '../../components/TittleCustom';
import { ButtonDeleteCustom } from '../../components/ButtonDeleteCustom';
import { ButtonQueryCustom } from '../../components/ButtonQueryCustom';
import { CheckBoxAgendaCustom } from '../../components/CheckBoxAgendaCustom';
import api_app from '../../apis/api_app';
import { store } from '../../redux/store';
import { Link, useParams } from 'react-router-dom';
import { ButtonDeleteAgendaCustom } from '../../components/ButtonDeleteAgendaCustom';
import { addDurationOnStartTime, combineDateTimeToIso } from '../../helpers/date_helpers';

interface RouteParams {
  id: string;

  [key: string]: string | undefined;
}

const AgendaProfissional: React.FC = () => {
  const [adicionarModalVisible, setAdicionarModalVisible] = useState(false);
  const [eventos, setEventos] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [consultModalVisible, setConsultModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [duration, setDuration] = useState('');
  const { id } = useParams<RouteParams>();
  const [modalVisible, setModalVisible] = useState(false);
  const [eventDetails, setEventDetails] = useState<any>(null);

  useEffect(() => {
    if (adicionarModalVisible) {
      const now = new Date();
      const today = now.toISOString().split('T')[0];
      const timeNow = now.toTimeString().split(' ')[0].slice(0, 5);

      setStartDate(today);
      setEndDate(today);
      setStartTime(timeNow);
      const [hours, minutes] = timeNow.split(':').map(Number);
      const newTime = new Date(now.getTime() + 30 * 60000);
      const newEndTime = `${newTime.getHours().toString().padStart(2, '0')}:${newTime.getMinutes().toString().padStart(2, '0')}`;
      setEndTime(newEndTime);
      setDuration('00:30');
    }
  }, [adicionarModalVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAgenda = await api_app.getAgendaProfissional(store.getState().user.user.id || 0);
        const newEventos = responseAgenda.data.map((ag: any) => {
          const start = new Date(ag.dia);
          const horario = new Date(ag.horario);
          start.setHours(horario.getHours());
          start.setMinutes(horario.getMinutes());
          start.setDate(start.getDate() + 1);
          const end = new Date(start.getTime() + 30 * 60000);
          return {
            title: ` - ${ag?.status_agenda?.status_agenda}`,
            start: start.toISOString(),
            end: end.toISOString(),
            id: ag.id,
            tipo: ag?.tipo_agenda?.tipo_agenda || 'Atendimento',
            paciente: ag.pessoa_agenda_paciente_idTopessoa?.nome || '',
            status: ag?.status_agenda?.status_agenda
          };
        });

        setEventos(newEventos);
      } catch (error) {
        console.error('Erro ao buscar agenda:', error);
      }
    };

    fetchData();
  }, [id]);

  const plugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, multiMonthPlugin];

  const handleSalvarEvento = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const startDate = formData.get('start') as string;
    const endDate = formData.get('end') as string;
    const startTime = formData.get('startTime') as string;
    const endTime = formData.get('endTime') as string;
    const duration = formData.get('duration') as string;
    const monday = formData.getAll('monday').includes('01');
    const tuesday = formData.getAll('tuesday').includes('02');
    const wednesday = formData.getAll('wednesday').includes('03');
    const thursday = formData.getAll('thursday').includes('04');
    const friday = formData.getAll('friday').includes('05');
    const saturday = formData.getAll('saturday').includes('06');
    const sunday = formData.getAll('sunday').includes('07');
    const week = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
    const newEventos = createEvents(startDate, endDate, startTime, endTime, duration, week);
    setAdicionarModalVisible(false);

    const eventosToPush = newEventos.map(async (appointment) => {
      const result = await api_app.postAgendas({
        dia: appointment.start,
        horario: appointment.horario,
        profissional_id: appointment.profissional_id,
        status_agenda_id: 1,
        dat_criado_em: new Date(),
        criada_por: appointment.profissional_id,
      })
        .then((resp) => {
          return { ...appointment, id: resp.data.id }
        })
        .catch((error) => {
          console.error('Erro ao buscar agendas:', error);
        });
      return result
    });

    const mappedEvents = await Promise.all(eventosToPush)

    setEventos([...eventos, ...mappedEvents])

  };

  const countConsultasPorDia = (eventos: any[], selectedDate: Date | null): number => {
    if (!selectedDate) return 0;

    const selectedDateStart = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const selectedDateEnd = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);

    return eventos.filter(evento => {
      const eventDate = new Date(evento.start);
      return eventDate >= selectedDateStart && eventDate < selectedDateEnd;
    }).length;
  };

  const createEvents = (
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    duration: string,
    week: boolean[]
  ): any[] => {
    const newEvents = [];
    let currentDate = new Date(startDate);
    const endDateTime = new Date(endDate);
    const professionalId = store.getState().user.user.id;

    const createEvent = (start: Date, end: Date, currentDate: Date): any => {
      return {
        title: 'Consulta',
        start: start.toISOString(),
        end: end.toISOString(),
        duration: duration,
        dia: currentDate,
        horario: start.toISOString(),
        profissional_id: professionalId,
        status: '- Livre',
        paciente: '- Livre'
      };
    };

    while (currentDate <= endDateTime) {
      const dayOfWeek = currentDate.getDay();

      if (week[dayOfWeek]) {
        let appointmentStartTime = combineDateTimeToIso(currentDate, startTime);
        const appointmentEndTime = combineDateTimeToIso(currentDate, endTime);

        while (appointmentStartTime < appointmentEndTime) {
          const eventEndTime = addDurationOnStartTime(appointmentStartTime, duration);
          if (eventEndTime > appointmentEndTime) break;

          newEvents.push(createEvent(appointmentStartTime, eventEndTime, currentDate));

          appointmentStartTime = new Date(eventEndTime.getTime() + 1000);
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return newEvents;
  };

  const handleDateSelect = (selectInfo: any) => {
    setSelectedDate(selectInfo.start);
    const eventsOnSelectedDate = eventos.filter(
      (evento) =>
        new Date(evento.start).getDate() === selectInfo.start.getDate() &&
        new Date(evento.start).getMonth() === selectInfo.start.getMonth() &&
        new Date(evento.start).getFullYear() === selectInfo.start.getFullYear()
    );
    setSelectedEvent(eventsOnSelectedDate);
    setConsultModalVisible(true);
  };

  const customEventContent = (eventInfo: any) => {
    const isListView = eventInfo.view.type === 'listWeek' || eventInfo.view.type === 'listDay';

    return (
      <div className="fc-event-content flex items-center justify-between">

        <div className="fc-event-main">
          <span>{eventInfo.timeText}</span>
          <span>{eventInfo.event.title}</span>
          <span>{eventInfo.event.status}</span>
        </div>
      </div>
    );
  };

  const handleEventClick = (info: any) => {
    console.log(info);
    setEventDetails({
      id: info.event.id,
      status: info.event.title,
      start: info.event.start,
      paciente: info.event.extendedProps.paciente,
      pureStatus: info.event.extendedProps.status
    });
    setModalVisible(true);
  };

  const deleteAppointment = async (id: number) => {
    setModalVisible(false)
    const appointments = eventos.filter(appointment => !(Number(appointment.id) === Number(id)))
    setEventos(appointments)
  }

  const podeDeletar = (consulta: any) => {
    const now = new Date();
    return consulta.start > now
  }

  const podeIrPraConsulta = (consulta: any) => {
    switch (consulta.pureStatus) {
      case "Confirmada":
        return true;
      case "Em andamento":
        return true;
      default:
        return false;
    }
  }

  return (
    <>
      <div className="flex items-center justify-between rounded-t mb-6 dark:border-gray-600">
        <TittleCustom name="Agenda Profissional" />
        <button
          className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white focus:ring-4 rounded-lg"
          style={{ backgroundColor: '#3b5179' }}
          onClick={() => setAdicionarModalVisible(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 mr-2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          Nova Agenda
        </button>
      </div>

      <FullCalendar
        plugins={plugins}
        initialView="dayGridMonth"
        locale={ptLocale}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listWeek',
        }}
        events={eventos}
        eventContent={customEventContent}
        editable={true}
        selectable={true}
        select={handleDateSelect}
        dayMaxEventRows={true}
        dayMaxEvents={true}
        eventClick={handleEventClick}
      />

      <Modal
        title="Agendamento de Consulta"
        visible={adicionarModalVisible}
        onCancel={() => setAdicionarModalVisible(false)}
        footer={null}
      >
        <form onSubmit={handleSalvarEvento}>
          <div className="grid gap-4 grid-cols-2">
            <div>
              <label className="block mb-1">Período Inicial:</label>
              <input
                type="date"
                name="start"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Período Inicial"
                required
              />
            </div>
            <div>
              <label className="block mb-1">Período Final:</label>
              <input
                type="date"
                name="end"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Período Final"
                required
              />
            </div>
            <div>
              <label className="block mb-1">Hora de Início:</label>
              <input
                type="time"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Hora de Início"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block mb-1">Hora Final:</label>
              <input
                type="time"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                placeholder="Hora Final"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
          </div>
          <legend className="mb-4">Dias da semana:</legend>
          <div className="grid grid-cols-2 gap-4">
            <fieldset className="max-w-md">
              <CheckBoxAgendaCustom
                label='Segunda-feira'
                value='01'
                name='monday'
              />
              <CheckBoxAgendaCustom
                label='Terça-feira'
                value='02'
                name='tuesday'
              />
              <CheckBoxAgendaCustom
                label='Quarta-feira'
                value='03'
                name='wednesday'
              />
              <CheckBoxAgendaCustom
                label='Quinta-feira'
                value='04'
                name='thursday'
              />
            </fieldset>
            <fieldset className="max-w-md">
              <CheckBoxAgendaCustom
                label='Sexta-feira'
                value='05'
                name='friday'
              />
              <CheckBoxAgendaCustom
                label='Sábado'
                value='06'
                name='saturday'
              />
              <CheckBoxAgendaCustom
                label='Domingo'
                value='07'
                name='sunday'
              />
            </fieldset>
          </div>
          <div className="mb-6">
            <label className="block mb-1">Tempo de Consulta:</label>
            <input
              type="time"
              name="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Tempo de consulta"
              required
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="py-2.5 px-5 text-sm font-medium text-white focus:ring-4 rounded-lg"
              style={{ backgroundColor: '#a7eb51' }}
            >
              Salvar
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={`Eventos do Dia - ${selectedDate ? selectedDate.toLocaleDateString('pt-BR') : ''}`}
        visible={consultModalVisible}
        onCancel={() => {
          setConsultModalVisible(false);
          setSelectedDate(null);
        }}
        footer={null}
        style={{ minWidth: '600px', maxWidth: '90%', margin: '20px auto' }}
      >
        {selectedEvent && (
          <div className="mb-4">
            <div className="border-b mb-4 justify-between items-center">
              <div>
                <table
                  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-3">
                  <thead
                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Horário
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Paciente
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Opções
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEvent.map((event: any, index: number) => (
                      <tr key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                        <td scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {new Date(event.start).toLocaleTimeString('pt-BR')}
                        </td>
                        <td scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {event.status || "- Livre"}
                        </td>
                        <td scope="row"
                          className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {event.paciente || "- Livre"}
                        </td>
                        <td className="px-6 py-3">
                          <div className="flex items-center">
                            <ButtonQueryCustom
                              link={'/dados-profissionais/agenda/atendimento-consulta/' + event.id} />
                            <ButtonDeleteCustom link={"/agenda/" + event.id} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className='mb-3'>Total de
                  consultas: {countConsultasPorDia(selectedEvent, selectedDate)}</p>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Detalhes da Consulta"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        destroyOnClose={true}
      >
        {eventDetails && (
          <div>
            <div className='mb-6'>
              <p><strong>Status da Consulta:</strong> {eventDetails.status.slice(2)}</p>
              <p><strong>Data:</strong> {new Date(eventDetails.start).toLocaleString()}</p>
              <p><strong>Paciente:</strong>{eventDetails.paciente}</p>
            </div>
            {podeIrPraConsulta(eventDetails) ?
              (<Link
                to={'/dados-profissionais/agenda/atendimento-consulta/' + eventDetails.id}
                type="button"
                className="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-900 dark:hover:bg-blue-800 focus:outline-none dark:focus:ring-blue-900"
                style={{ backgroundColor: '#598c89' }}
              >
                Ir Para Consulta
              </Link>) : (
                <div> Para ir a consulta, esta deve estar <strong>Confirmada</strong> ou <strong>Em Andamento</strong></div>
              )
            }
            {podeDeletar(eventDetails) &&
              <ButtonDeleteAgendaCustom
                deleteAppointment={deleteAppointment}
                id={eventDetails.id}
              />}
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgendaProfissional;
