import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import SpinnerCustom from './SpinnerCustom';

interface PropertiesCustom {
    name: string;
    label: string;
    type: string;
    value: string;
    placeholder: string;
    mask?: string;
    loading?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    errors?: string;
    required?: boolean;
}

const InputCustom: React.FC<PropertiesCustom> = (props) => {
    const [isEmpty, setIsEmpty] = useState(props.value === '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsEmpty(event.target.value === '');
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className="mb-2 w-full">
            <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white" htmlFor={props.name}>
                {props.label} {props.errors && <span className="text-red-500"> ({props.errors})</span>}
            </label>
            <div className="relative">
                {props.loading && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                       <SpinnerCustom />
                    </div>
                )}
                {props.mask ? (
                    <InputMask
                        mask={props.mask}
                        name={props.name}
                        className={`bg-gray-50 border ${props.loading ? 'border-blue-300' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder={props.placeholder}
                        type={props.type}
                        value={props.value}
                        onChange={handleChange}
                        required={props.required}
                        disabled={props.loading}
                    />
                ) : (
                    <input
                        name={props.name}
                        className={`bg-gray-50 border ${props.loading ? 'border-blue-300' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder={props.placeholder}
                        type={props.type}
                        value={props.value}
                        onChange={handleChange}
                        required={props.required}
                        disabled={props.loading}
                    />
                )}
            </div>
        </div>
    );
};

export default InputCustom;
