import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";
import { SelectEspecialidadeCustum } from "./SelectEspecialidadesCustum";
import withRouter from "../../../../../helpers/withRouter";
import {pessoa_emailModel, pessoa_especialidadeModel, profissaoModel} from "../../../../../resource/request";
import {store} from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import FileInputCustom from "../../../../../components/FileInputCustom";

interface State {
    especialidades: pessoa_especialidadeModel,
    msg: string,
    profissaoModel: profissaoModel;
    isSubmitted: boolean;

}

export default withRouter( class EditEspecialidade extends Component<any>{

    state: State = {
        especialidades: {} as pessoa_especialidadeModel,
        msg: "",
        profissaoModel:{}  as profissaoModel,
        isSubmitted: false,

    }


    componentDidMount() {
        this.buscarDadosDoBanco();


    }
    buscarDadosDoBanco()  {
        const id = this.props.router.params.id
        const espec = store.getState().user.user.pessoa_especialidade
        espec.map(especialidades => {
            if (especialidades?.id?.toString() === id.toString()) {
                this.setState({ especialidades });
            }
        });

    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        if (name === 'profissao') {
            this.setState({
                profissaoModel: {
                    ...this.state.profissaoModel,
                    id: parseInt(value)
                }
            });
        } else {
            this.setState({
                especialidades: {
                    ...this.state.especialidades,
                    [name]: value
                }
            });
        }

    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarEspecialidade(this.state.especialidades.id, this.state.especialidades).then(x => {
                const msg = "Seus dados foram atualizados com suscesso!";
                this.setState({msg, isSubmitted: true });
                this.props.router.updateStore();
            }
        )
    };

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Especialidade"
                    />
                    <ButtonReturnCustom
                        link="/dados-profissionais"
                    />
                </div>
                {/* {this.state.especialidades.especialidade_id||0} */}
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                <SelectEspecialidadeCustum
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="UF:"
                            value={this.state.especialidades.uf || ''}
                            type="text"
                            name="uf"
                            placeholder="UF"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Nº Registro/Nº RQE:"
                            value={this.state.especialidades.num_especialidade_rqe || ''}
                            type="text"
                            name="num_especialidade_rqe"
                            placeholder="Nº Especialidades/ Nº RQE"
                            onChange={this.handleChange}
                        />
                        <FileInputCustom
                            label="Título De Especialidade (Opcional):"
                            name="titulo-de-especialidade"
                            value=""
                        />
                        <InputCustom
                            label="Valor da Consulta:"
                            value={this.state?.especialidades?.vlr_atendimento?.toString() || ''}
                            type="text"
                            name="vlr_atendimento"
                            placeholder="Valor da Consulta"
                            onChange={this.handleChange}
                        />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                        type=""
                        name="Salvar"
                    />
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
})